import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserAgentService} from '@services/user-agent.service';
import {ApplicationSessionService} from '@services/application-session.service';
import {RouteConfigLoadEnd, RouteConfigLoadStart, Router} from '@angular/router';
import {Subscription} from 'rxjs';


@Component({
  selector: 'app-thing',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  hasBadBrowser: boolean;
  dismissed: boolean;

  loadingRouteConfig: boolean;
  routerSubscription: Subscription;

  constructor(public sessionService: ApplicationSessionService,
              private userAgentService: UserAgentService,
              private router: Router) {
  }

  ngOnInit() {
    const browser = this.userAgentService.detectBrowser(navigator.userAgent);
    // If the browser user agent string returns null/undefined (Some versions of IE) or _not_ either Chrome or Firefox, throw an alert.
    this.hasBadBrowser = !browser || !(browser.includes('Chrome') || browser.includes('Firefox'));

    this.moduleLoadingSpinner();
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  dismissBrowserPopup() {
    this.dismissed = true;
  }

  moduleLoadingSpinner() {
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.loadingRouteConfig = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loadingRouteConfig = false;
      }
    });
  }
}
