import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserAgentService {

  regexps = {
    'Firefox': [/Firefox\/(\S+)/],
    'Chrome': [/Chrome\/(\S+)/],
    'MSIE': [/MSIE (\S+);/],
    'Edge': [/Edge (\d+)(?:\.(\d+))?/],
    'Opera': [
      /Opera\/.*?Version\/(\S+)/, /* Opera 10 */
      /Opera\/(\S+)/ /* Opera 9 and older */
    ],
    'Safari': [/Version\/(\S+).*?Safari\//]
  };

  constructor() {
  }

  public detectBrowser(userAgent: string): string {
    let version;
    let elements = 2;

    for (const browser in this.regexps) {
      if (this.regexps.hasOwnProperty(browser)) {
        const re = this.regexps[browser];
        const m = userAgent.match(re[0]);
        if (m) {
          version = (m[1].match(new RegExp('[^.]+(?:\.[^.]+){0,' + --elements + '}')))[0];
          return (version && `${browser} ${version}`) || 'No Browser';
        }
      }
    }
  }
}
