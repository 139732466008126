import {AppComponent} from './app.component';
import {BootstrapModalModule} from 'ng2-bootstrap-modal';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {CommonModule} from '@angular/common';
import {environment} from '../environments/environment';
import {LoggerModule, NgxLoggerLevel} from 'ngx-logger';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SidebarModule} from 'ng-sidebar';
import {ToastrModule} from 'ngx-toastr';
import {ApplicationSessionService} from '@services/application-session.service';
import { TawkComponent } from './shared-modules/tawk/tawk/tawk.component';


const ROUTE_DEBUG_TRACING = false;
const ROUTES: Routes = [
  {path: '', pathMatch: 'full', redirectTo: '/app/landing'},
  {path: 'app', loadChildren: './mabble-instance/mabble-instance.module#MabbleInstanceModule'},
  {path: 'authentication', redirectTo: '/app/authentication'},
  {path: 'error', redirectTo: '/app/error'},

  {path: '**', redirectTo: '/app/error/404'}
];

@NgModule({
  declarations: [
    AppComponent,
    TawkComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    RouterModule.forRoot(ROUTES, {
      enableTracing: ROUTE_DEBUG_TRACING,
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled'
    }),

    LoggerModule.forRoot({
      level: environment.production ? NgxLoggerLevel.DEBUG : NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.OFF
    }),

    NgbModule.forRoot(),
    BootstrapModalModule.forRoot({container: document.body}),
    SidebarModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 0,
      closeButton: true,
    }),
  ],
  providers: [ApplicationSessionService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
