import * as _ from 'lodash';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {
  ApplicationApi,
  ApplicationSession,
  ApplicationSessionUser,
  InstanceConfiguration,
  MabbleModule,
  MabbleModuleContentItem,
  MabbleModuleWidget,
  MabbleModuleWidgetContainer,
  MBL_KEY_MODULES, MBL_STATUS_READY,
  MBL_TYPE_CONTAINER,
  MBL_TYPE_CONTENT,
  MBL_TYPE_INSTANCE,
  MBL_TYPE_MENU_ITEM,
  MBL_WIDGET_STYLE_A,
  MBL_WIDGET_STYLE_NAV_ITEM,
  MBL_WIDGET_TYPE_LINK,
  MBL_WIDGET_TYPE_WIDGET,
  MblObjectFormControl,
  uuidv4
} from '@core/models/application-session.model';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

/**
 * mzs: 2017-11-14.120612
 * mzs: 2017-12-27.153858
 * mzs: 2018-01-05.070827
 * ...
 * */
export class SessionEvent {
  _onion: number;
  activeRecord?: any;
  event: string;
  key: string;
  source: any;
  module?: MabbleModule<any>;

  constructor(options?: any) {
    this._onion = (this._onion || options && options._onion || 0) + 1;
    this.activeRecord = options && options.activeRecord;
    this.event = options && options.event;
    this.key = options && options.key;
    this.source = options && options.source;
    this.module = options && options.module;

    if (this.source && this.source._onion) {
      this._onion = this._onion + this.source._onion;
    }

  }


}

export class WatcherStore {
  private _map: [{ [p: string]: BehaviorSubject<any> }];

  constructor(root: object) {
    this._map = [{
      root: new BehaviorSubject(root)
    }];
  }

  next(key: string, value?: any): BehaviorSubject<any> {
    const reply = this.get(key);
    if (value) {
      reply.next(value);
    }
    return reply;
  }

  private get(key: string): BehaviorSubject<any> {
    const _m = this._map[key];
    if (_m) {
      return _m;
    }
    this._map[key] = new BehaviorSubject({});
    return this._map[key];
  }
}

@Injectable()
export class ApplicationSessionService {
  private _watchers: WatcherStore = new WatcherStore({});

  private application: ApplicationSession = new ApplicationSession({});
  private anonymous: ApplicationSessionUser;

  private admin_roles: string[] = ['ROLE_ADMINISTRATOR', 'ROLE_SYS_ADMIN'];
  private granted_partner_role = 'ROLE_GRANTED_PARTNER';
  private cgsa_coordinator_role = 'ROLE_CGSA_COORDINATOR';
  private cgsa_admin_role = 'ROLE_CGSA_ADMIN';
  loading = true;

  constructor() {
    this.fetch('root-application-session.seed.json', (rootdata) => {
      this.fetch(environment.seed, (instancedata) => {

        const seed = _.merge({}, rootdata, instancedata, {
          enter_any_emergency_override_here: false
        });

        this.application = new ApplicationSession(seed);
        this.application.instance.state.next(this.seedInstanceConfiguration());

        // prepare a singleton watcher store
        this._watchers = new WatcherStore(this.application);

        // init session wide roles
        this.admin_roles = ['ROLE_ADMINISTRATOR', 'ROLE_SYS_ADMIN'];
        if (this.application.defaults.admin_roles) {
          this.application.defaults.admin_roles.forEach(ar => this.admin_roles.push(ar));
        }

        // publish the session
        this.fire();
        this.loading = false;
      })
    });
  }

  get adminRoles(): string[] {
    return [...this.admin_roles];
  }

  get grantedPartnerRole(): string {
    return this.granted_partner_role;
  }

  get cgsaCoordinatorRole(): string {
    return this.cgsa_coordinator_role;
  }

  get cgsaAdminRole(): string {
    return this.cgsa_admin_role;
  }

  /**
   * Best effort generate a display control set for any given model.
   * @param model to interrogate
   * @return [] of MabbleFormControl items.
   */
  public buildModelControls(model: any): MblObjectFormControl[] {
    if (model) {
      const results: Array<MblObjectFormControl> = [];
      Object.keys(model).forEach(k => {
        results.push(new MblObjectFormControl({
          model: model[k],
          name: model.name || model[k] || model._mblId,
          label: k,
          type: 'text',
          supportsHorizontal: true
        }));
      });
      return results;
    }
  }

  public fire() {
    this._watchers.next('menu', this.application.menu);
    this._watchers.next('session.api', this.application.api);
    this._watchers.next('user', this.application.user);
    this._watchers.next('user.data', this.application.user.data);

    this._watchers.next('_root', this.application);
  }


  public set(key: string, value: any) {
    switch (key) {
      case('session.auth.token'): {
        this.application.user.auth_token = value;
        this._watchers.next('user', this.application.user);
        this._watchers.next('user.data', this.application.user.data);
        break;
      }
      case('session.user'): {
        this.application.user = value;
        this._watchers.next('user', this.application.user);
        this._watchers.next('user.data', this.application.user.data);
        break;
      }
      case('session.mabble.host.auth.token'): {
        this.application.mabbleUser.auth_token = value;
        this._watchers.next('mabbleUser', this.application.mabbleUser);
        this._watchers.next('mabbleUser.data', this.application.mabbleUser.data);

        this.application.user.auth_token = value;
        this._watchers.next('user', this.application.mabbleUser);
        this._watchers.next('user.data', this.application.mabbleUser.data);
        break;
      }
      case('session.mabble.host.user'): {
        this.application.mabbleUser = value;
        this._watchers.next('mabbleUser', this.application.mabbleUser);
        this._watchers.next('mabbleUser.data', this.application.mabbleUser.data);

        this.application.user = value;
        this._watchers.next('user', this.application.mabbleUser);
        this._watchers.next('user.data', this.application.mabbleUser.data);
        break;
      }
      case ('user'):
      case ('user.data'):
      case ('session.user.data'): {
        this.application.user.data = value;
        this._watchers.next('user.data', this.application.user.data);
        break;
      }
      default: {
        this._watchers.next(key).getValue();
      }
    }
    this._watchers.next('_root', this.application);
  }


  public get(key: string): any {
    switch (key) {
      case (''): {
        break;
      }
      case ('api'): {
        return this.application.api;
      }
      case ('apiHost'):
      case ('api.host'): {
        return this.application.api.host;
      }
      case ('user.data'): {
        return this.application.user.data;
      }
      case ('default.home.route'): {
        return this.application.defaults.home.route;
      }
      case ('session'): {
        return this.application;
      }
      case ('session.user'): {
        return this.application.user;
      }
      default: {
      }
    }
  }


  public observe(key: string): BehaviorSubject<ApplicationSession | ApplicationApi | ApplicationSessionUser | any> |
    Observable<ApplicationSession | ApplicationApi | ApplicationSessionUser | any> {
    switch (key) {
      case ('api') :
      case ('session.api') : {
        return this._watchers.next(key, this.application.api);
      }
      case ('session.m_api') : {
        return this._watchers.next(key, this.application.m_api);
      }
      case ('apiHost') :
      case ('api.host') : {
        return of(this.application.api.host);
      }
      case ('login.page') : {
        return of(this.application.login.page);
      }
      case ('menu') : {
        return this._watchers.next('menu', this.application.menu);
      }
      case ('is-authenticated') :
      case ('user') :
      case ('users') :
      case ('session.user') : {
        return this._watchers.next('user', this.application.user);
      }
      case ('session.mabble.host.user') : {
        return this._watchers.next('mabbleUser', this.application.mabbleUser);
      }
      case ('session.user.data') :
      case ('user.data') : {
        if (this.application.mabbleUser && this.application.mabbleUser.data
          && this.application.mabbleUser.auth_token && this.application.mabbleUser.auth_token.length > 0) {
          return this._watchers.next('mabbleUser.data', this.application.mabbleUser.data);
        } else {
          return this._watchers.next('mabbleUser.data', this.anonymous.data);
        }
      }
      case ('session') :
      default: {
        return this._watchers.next('_root');
      }
    }
  }


  public subscribe(key: string): BehaviorSubject<ApplicationSession | ApplicationApi | ApplicationSessionUser | any> {
    switch (key) {
      case ('menu') : {
        return this._watchers.next('menu', this.application.menu);
      }
      case ('is-authenticated') :
      case ('user') :
      case ('users') :
      case ('session.user') : {
        return this._watchers.next('user', this.application.user);
      }
      case ('user.data') :
      case ('session.user.data') : {
        if (this.application.user && this.application.user.data
          && this.application.user.auth_token && this.application.user.auth_token.length > 0) {
          return this._watchers.next('user.data', this.application.user.data);
        } else {
          return this._watchers.next('user.data', this.anonymous.data);
        }
      }
      case ('session') :
      default: {
        return this._watchers.next('_root', this.application);
      }
    }
  }


  private fetch(datkey: string, cb: any) {
    const req = new XMLHttpRequest();
    req.open('GET', 'assets/data/seed/' + datkey);
    req.onload = () => cb(JSON.parse(req.response));
    req.send();
  }


  private seedInstanceConfiguration(): InstanceConfiguration {
    const MBL_MODULE_KEY_MCIL_UUID = uuidv4();

    // top nav
    const home_link = new MabbleModuleWidget({
      name: 'Home Menu Item',
      description: 'A regular link (i.e href) to the home page',
      status: MBL_STATUS_READY,
      type: MBL_TYPE_MENU_ITEM,
      properties: {
        widget_route: '/',
        widget_title: 'Home',
        widget_style: MBL_WIDGET_STYLE_NAV_ITEM,
        widget_type: MBL_WIDGET_TYPE_LINK
      }
    });
    const configuration_link = new MabbleModuleWidget({
      name: 'Configuration Menu Item',
      description: 'A regular link (i.e href) to the configuration component page',
      status: MBL_STATUS_READY,
      type: MBL_TYPE_MENU_ITEM,
      accessControl: {
        roles: ['ROLE_SYS_ADMIN']
      },
      properties: {
        widget_route: '/app/sec/config/home',
        widget_title: 'Configuration',
        widget_style: MBL_WIDGET_STYLE_NAV_ITEM,
        widget_type: MBL_WIDGET_TYPE_LINK
      }
    });
    const logout_link = new MabbleModuleWidget({
      name: 'Log Out Menu Item',
      description: 'A regular link (i.e href) performing the user logout page',
      status: MBL_STATUS_READY,
      type: MBL_TYPE_MENU_ITEM,
      accessControl: {
        roles: ['ROLE_SYS_ADMIN']
      },
      properties: {
        widget_route: '/app/authentication/signout',
        widget_title: 'Log Out',
        widget_style: MBL_WIDGET_STYLE_NAV_ITEM,
        widget_type: MBL_WIDGET_TYPE_LINK
      }
    });
    const login_link = new MabbleModuleWidget({
      name: 'Log In Menu Item',
      description: 'A regular link (i.e href) performing the user login page',
      status: MBL_STATUS_READY,
      type: MBL_TYPE_MENU_ITEM,
      accessControl: {
        roles: ['ROLE_SYS_ADMIN']
      },
      properties: {
        widget_route: '/app/authentication/signin',
        widget_title: 'Log In',
        widget_style: MBL_WIDGET_STYLE_NAV_ITEM,
        widget_type: MBL_WIDGET_TYPE_LINK
      }
    });
    const reports_link = new MabbleModuleWidget({
      name: 'Committee Reports Link',
      description: 'A regular link (i.e href) to the committee reports page',
      status: MBL_STATUS_READY,
      type: MBL_TYPE_MENU_ITEM,
      properties: {
        widget_route: '/app/sec/grant-management/committee-recommendation',
        widget_title: 'GM Reports',
        widget_style: MBL_WIDGET_STYLE_NAV_ITEM,
        widget_type: MBL_WIDGET_TYPE_LINK
      }
    });
    const dashboard_link = new MabbleModuleWidget({
      name: 'Grant Management Dashboard Link',
      description: 'A regular link (i.e href) to the Grant Management Dashboard',
      status: MBL_STATUS_READY,
      type: MBL_TYPE_MENU_ITEM,
      properties: {
        widget_route: '/app/sec/grant-management/home',
        widget_title: 'Dashboard',
        widget_style: MBL_WIDGET_STYLE_NAV_ITEM,
        widget_type: MBL_WIDGET_TYPE_LINK
      }
    });
    const content_manager = new MabbleModuleWidget({
      status: MBL_STATUS_READY,
      type: MBL_WIDGET_TYPE_WIDGET,
      accessControl: {
        roles: ['ROLE_SYS_ADMIN', 'ROLE_ADMINISTRATOR', 'ROLE_GRANT_MANAGER']
      },
      properties: {
        widget_classes: 'icon-basic-book-pencil',
        widget_color: 'light-blue',
        widget_title: 'Content Manager',
        widget_style: MBL_WIDGET_STYLE_A,
        widget_route: '/app/sec/content/manager/home',
        widget_type: MBL_WIDGET_TYPE_LINK,
        widget_text: 'content management system',
        widget_width: '4'
      }
    });
    const coordinators_widget = new MabbleModuleWidget({
      status: MBL_STATUS_READY,
      type: MBL_WIDGET_TYPE_WIDGET,
      accessControl: {
        roles: ['ROLE_SYS_ADMIN', 'ROLE_ADMINISTRATOR', 'ROLE_GRANT_MANAGER']
      },
      properties: {
        widget_classes: 'icon-basic-book-pencil',
        widget_color: 'yellow',
        widget_title: 'Coordinator Management',
        widget_style: MBL_WIDGET_STYLE_A,
        widget_route: '/app/sec/coordinators',
        widget_type: MBL_WIDGET_TYPE_LINK,
        widget_text: 'coordinator role management',
        widget_width: '4'
      }
    });
    const forms_dashboard_widget = new MabbleModuleWidget({
      status: MBL_STATUS_READY,
      type: MBL_WIDGET_TYPE_WIDGET,
      accessControl: {
        roles: ['ROLE_SYS_ADMIN', 'ROLE_ADMINISTRATOR']
      },
      properties: {
        widget_classes: 'icon-basic-lightbulb',
        widget_color: 'green',
        widget_title: 'Form Builder Dashboard',
        widget_style: MBL_WIDGET_STYLE_A,
        widget_route: '/app/sec/form/dashboard',
        widget_type: MBL_WIDGET_TYPE_LINK,
        widget_text: 'form builder',
        widget_width: '4'
      }
    });
    const usc_grant_application_widget = new MabbleModuleWidget({
      name: 'Grant Management: Applications',
      status: MBL_STATUS_READY,
      type: MBL_WIDGET_TYPE_WIDGET,
      accessControl: {
        roles: ['ROLE_SYS_ADMIN', 'ROLE_ADMINISTRATOR', 'ROLE_APPLICANT']
      },
      properties: {
        widget_classes: 'icon-basic-keyboard',
        widget_color: 'brown',
        widget_title: 'Grant Application',
        widget_style: MBL_WIDGET_STYLE_A,
        widget_route: '/app/app.workflow.grant.application.csu',
        widget_type: MBL_WIDGET_TYPE_LINK,
        widget_text: 'Grant Management: Application collections',
        widget_width: '4'
      }
    });
    const usc_grant_management_widget = new MabbleModuleWidget({
      name: 'Grant Management',
      status: MBL_STATUS_READY,
      type: MBL_WIDGET_TYPE_WIDGET,
      accessControl: {
        roles: ['ROLE_SYS_ADMIN', 'ROLE_ADMINISTRATOR', 'ROLE_GRANT_MANAGER', 'ROLE_GRANT_COORDINATOR']
      },
      properties: {
        widget_classes: 'icon-basic-keyboard',
        widget_color: 'red',
        widget_title: 'Grant Management: Dashboard',
        widget_style: MBL_WIDGET_STYLE_A,
        widget_route: '/app/sec/grant-management/home',
        widget_type: MBL_WIDGET_TYPE_LINK,
        widget_text: 'Grant Management: Application Dashboard',
        widget_width: '4'
      }
    });

    const usc_grant_management_reports_widget = new MabbleModuleWidget({
      name: 'Reports',
      status: MBL_STATUS_READY,
      type: MBL_WIDGET_TYPE_WIDGET,
      accessControl: {
        roles: ['ROLE_SYS_ADMIN', 'ROLE_ADMINISTRATOR', 'ROLE_GRANT_MANAGER', 'ROLE_GRANT_COORDINATOR']
      },
      properties: {
        widget_classes: 'icon-basic-headset',
        widget_color: 'blue',
        widget_title: 'Grant Management: Reports',
        widget_style: MBL_WIDGET_STYLE_A,
        widget_route: '/app/sec/grant-management/committee-recommendation',
        widget_type: MBL_WIDGET_TYPE_LINK,
        widget_text: 'Grant Management: Reports',
        widget_width: '4'
      }
    });
    const usc_grant_window_widget = new MabbleModuleWidget({
      name: 'Grant Window',
      status: MBL_STATUS_READY,
      type: MBL_WIDGET_TYPE_WIDGET,
      accessControl: {
        roles: ['ROLE_SYS_ADMIN', 'ROLE_ADMINISTRATOR', 'ROLE_GRANT_MANAGER', 'ROLE_GRANT_COORDINATOR']
      },
      properties: {
        widget_classes: 'icon-basic-headset',
        widget_color: 'green',
        widget_title: 'Grant Window',
        widget_style: MBL_WIDGET_STYLE_A,
        widget_route: '/app/sec/grant-window/home',
        widget_type: MBL_WIDGET_TYPE_LINK,
        widget_text: 'Grant Window',
        widget_width: '4'
      }
    });

    const user_admin_widget = new MabbleModuleWidget({
      status: MBL_STATUS_READY,
      type: MBL_WIDGET_TYPE_WIDGET,
      accessControl: {
        roles: ['ROLE_SYS_ADMIN', 'ROLE_ADMINISTRATOR']
      },
      properties: {
        widget_classes: 'fa fa-address-card-o',
        widget_color: 'blue',
        widget_title: 'Users',
        widget_style: MBL_WIDGET_STYLE_A,
        widget_route: '/app/sec/users/list',
        widget_type: MBL_WIDGET_TYPE_LINK,
        widget_text: 'User Administration',
        widget_width: '4'
      }
    });

    const reporting_landing_widget = new MabbleModuleWidget({
      status: MBL_STATUS_READY,
      type: MBL_WIDGET_TYPE_WIDGET,
      accessControl: {
        roles: ['ROLE_SYS_ADMIN', 'ROLE_ADMINISTRATOR']
      },
      properties: {
        widget_classes: 'icon-basic-webpage-img-txt',
        widget_color: 'red',
        widget_title: 'Partner Landing Page',
        widget_style: MBL_WIDGET_STYLE_A,
        widget_route: '/app/sec/partner-landing/home',
        widget_type: MBL_WIDGET_TYPE_LINK,
        widget_text: 'Partner Landing',
        widget_width: '4'
      }
    });


    // modules:
    const sec_instance_top_nav = {
      dev: [
        home_link,
        configuration_link,
        logout_link
      ],
      usc: [
        reports_link,
        dashboard_link,
      ]
    };
    const sec_instance_contains = {
      usc: [
        coordinators_widget,
        forms_dashboard_widget,
        usc_grant_window_widget,
        usc_grant_application_widget,
        usc_grant_management_widget,
        usc_grant_management_reports_widget,
        reporting_landing_widget
      ],
      dev: [
        content_manager,
        coordinators_widget,
        forms_dashboard_widget,
        usc_grant_window_widget,
        usc_grant_application_widget,
        usc_grant_management_widget,
        usc_grant_management_reports_widget,
        user_admin_widget
      ]
    };
    const secuser_instance_contains = {
      dev: [
        usc_grant_window_widget,
        coordinators_widget,
        usc_grant_management_widget,
        usc_grant_management_reports_widget
      ],
      usc: [
        usc_grant_window_widget,
        coordinators_widget,
        usc_grant_management_widget,
        usc_grant_management_reports_widget
      ]
    };

    const pub_instance_main = new MabbleModuleContentItem({
      name: 'Public Landing Page Content',
      description: 'The general public facing content used on the welcome page.',
      status: MBL_STATUS_READY,
      type: MBL_TYPE_CONTENT,
      context: this.application.instance.homeContentKey
    });
    const sec_instance_main = new MabbleModuleWidgetContainer({
      name: 'Admin Landing Page Content',
      description: 'The content used on the administration dashboard.',
      status: MBL_STATUS_READY,
      type: MBL_TYPE_CONTAINER,
      contains: sec_instance_contains[this.application.ssitk]
    });
    const secuser_instance_main = new MabbleModuleWidgetContainer({
      name: 'User Landing Page Content',
      description: 'The content used on the user home.',
      status: MBL_STATUS_READY,
      type: MBL_TYPE_CONTAINER,
      contains: secuser_instance_contains[this.application.ssitk]
    });

    const pub = new InstanceConfiguration({
      properties: {
        instance_brand_banner_img: this.application.defaults.home.banner,
        instance_brand_banner_visible: true,
        instance_brand_logo_img: this.application.defaults.home.logo,
        instance_top_nav: [home_link, login_link],
        instance_main: pub_instance_main
      }
    });
    const sec = new InstanceConfiguration({
      properties: {
        instance_top_nav: (this.application.ssitk === 'sus'
          ? [home_link, logout_link]
          : sec_instance_top_nav[this.application.ssitk]),
        instance_brand_banner_visible: !this.application.user || !this.application.user.isAuthenticated(),
        instance_left_sidebar_visible: false,
        instance_main: sec_instance_main
      }
    });
    const secuser = new InstanceConfiguration({
      properties: {
        instance_top_nav: [home_link, logout_link],
        instance_brand_banner_visible: !this.application.user || !this.application.user.isAuthenticated(),
        instance_left_sidebar_visible: false,
        instance_main: secuser_instance_main
      }
    });
    return new InstanceConfiguration({
      _mblId: MBL_MODULE_KEY_MCIL_UUID,
      type: MBL_TYPE_INSTANCE,
      context: MBL_KEY_MODULES,
      identifier: 1,
      name: 'Instance Configuration',
      description: 'Application module configuration for landing page bootstrap.',
      status: MBL_STATUS_READY,
      properties: {
        instance_top_nav: [home_link, logout_link],
        instance_left_sidebar_visible: !this.application.user || !this.application.user.isAuthenticated(),
        instance_main: {},
      },
      initialStates: [{key: 'pub', state: pub}, {key: 'sec', state: sec}, {key: 'secuser', state: secuser}]
    });

  }


}
